<template>
    <div class="add">
        <el-dialog :title="'添加用户'" :visible.sync="dialogVisible" width="50%" :show-close="false">
            <div class="addGauge">
                <div class="searchBtn">
                    <el-input size="small" placeholder="请输入姓名" clearable style="width: 150px"
                        v-model="searchInfo.nickname">
                    </el-input>
                    <el-select size="small" width="150px" v-model="searchInfo.roleId" placeholder="请选择角色">
                        <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small"
                        placeholder="请选择部门" :show-all-levels="false"
                        :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"></el-cascader>
                    <!-- <el-select size="small" width="150px" v-model="searchInfo.organizationId" placeholder="请选择部门">
                        <el-option v-for="item in OrganizationsList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-button type="primary" size="small" @click="search">查询</el-button>
                </div>
                <div class="tableList">
                    <el-table :data="tableData" border style="width: 100%" size="small" height="300px">
                        <el-table-column width="50">
                            <template slot-scope="scope">
                                <el-radio v-model="userId" :label="scope.row.id">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nickname" label="姓名" width="150">
                        </el-table-column>
                        <el-table-column prop="username" label="登录名" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="角色" prop="roleName" width="200">
                        </el-table-column>
                        <el-table-column label="部门" prop="departmentName" width="250" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="addGauge addCounselor">
                <div class="titleContent">
                    <span>关联咨询师</span>
                </div>
                <!-- <div class="searchBtn">
                    <el-input size="small" placeholder="请输入姓名" clearable style="width: 150px"
                        v-model="searchInfo.nickname">
                    </el-input>
                    <el-select size="small" width="150px" v-model="searchInfo.roleId" placeholder="请选择角色">
                        <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small"
                        placeholder="请选择部门" :show-all-levels="false"
                        :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"></el-cascader>
                   
                    <el-button type="primary" size="small" @click="search">查询</el-button>
                </div> -->
                <div class="tableList">
                    <el-table :data="TeacherData" border style="width: 100%" size="small" height="300px">
                        <el-table-column width="50">
                            <template slot-scope="scope">
                                <el-radio v-model="teacherId" :label="scope.row.id">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nickname" label="姓名" width="150">
                        </el-table-column>
                        <el-table-column prop="username" label="登录名" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="角色" prop="roleName" width="200">
                        </el-table-column>
                        <el-table-column label="部门" prop="departmentName" width="200" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submit" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { getUsersList } from '@/api/system/users'
import { updateArchives, getTeachersList } from '@/api/dangerWarning/crisisIntervention'
export default {
    data() {
        return {
            dialogVisible: false,
            searchInfo: {
                roleId: null,
                departmentId: null,
                nickname: ''
            },
            addUserIdList: [],
            num: 0,
            teacherId: null, // 关联的咨询师的id
            userId: null, // 用户的id
            tableData: [], // 用户数组
            TeacherData: [], // 咨询师用户
            OrganizationsList: [], //组织机构列表
            RolesList: [], // 角色列表
            previewUserList: [], // 用于保存之前已经选择的用户列表
            allUsersList: [] // 用于保存所有用户列表
        }
    },
    methods: {
        // 打开当前模态框
        openAdd(val) {
            this.previewUserList = val;
            this.getUserList();
            this.getTeacherList();
            this.getOrganizationsList();
            this.getRolesList();
            this.teacherId = null;
            this.userId = null;
            this.dialogVisible = true;
        },
        // 获取用户列表
        async getUserList() {
            let info = JSON.parse(JSON.stringify(this.searchInfo));
            console.log(info);
            // 判断是否选择的是全部
            // departmentId、roleId为-1时，则选择的是全部，需要把值改为null
            if (info.departmentId === -1) {
                info.departmentId = null;
            }
            if (info.roleId === -1) {
                info.roleId = null;
            }
            const res = await getUsersList({ p: 1, ps: 100000, ...info });
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.userVOList;
                // this.TeacherData = res.data.userVOList
                //     this.allUsersList = res.data.userVOList;
                //     // this.tableData = res.data.userVOList;
                //     // let usersList = [];
                //     // 将之前已经选择的去掉
                //    this.tableData = this.allUsersList.filter(a=> !this.previewUserList.some(b=>b.id === a.id))
                // 如果筛选的数据是正确的，那么直接把筛选出来的值赋值给tableData
            }
        },
        // 获取咨询师列表
        async getTeacherList() {
            const res = await getTeachersList();
            console.log(res);
            if (res.code === 200) {
                this.TeacherData = res.data;
            }
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            // console.log('组织机构');
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                // 在组织机构列表数组中加入全部选项
                this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
                // 递归删除children为空的数据
                // this.deleteNullChildren(this.OrganizationsList)
            }
        },
        // 使用递归取出children为空的数据
        deleteNullChildren(val) {
            // console.log(val);
            if (val) {
                val.forEach(item => {
                    if (item.children.length < 1) {
                        delete item.children;
                    }
                    this.deleteNullChildren(item.children)
                });
            }
        },
        // 获取角色列表
        async getRolesList() {
            const res = await getRoleList();
            console.log(res);
            if (res.code === 200) {
                this.RolesList = res.data;
                // 在角色数组列表中加入全部选项
                this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
            }
        },
        // 点击搜索
        async search() {
            // console.log(this.searchInfo);
            this.getUserList();
        },
        // 点击选择
        handleSelectionChange(val) {
            console.log(val);
            if (val) {
                this.num = val.length;
                this.addUserIdList = [];
                val.forEach(item => {
                    this.addUserIdList.push({ userId: item.id, planId: Number(sessionStorage.getItem('planId')) });
                })
            }
        },
        // 点击确定
        async submit() {
            console.log(this.teacherId);
            console.log(this.userId)
            if (this.teacherId === null || this.teacherId === '') {
                this.$message({
                    showClose: true,
                    message: '请选择关联咨询师'
                });
            } else if (this.userId === null || this.userId === '') {
                this.$message({
                    showClose: true,
                    message: '请选择用户'
                });
            } else {
                let info = { consultantId: this.teacherId, userId: this.userId, status: 1 };
                const res = await updateArchives(JSON.stringify(info));
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        showClose: true,
                        message: '新增成功！',
                        type: 'success'
                    });
                    this.$emit('getList');
                    this.dialogVisible = false;
                } else {
                    this.$message({
                        showClose: true,
                        message: '新增失败！' + res.msg,
                        type: 'error'
                    });
                }
            }

        }

    },
    created() {

    }

}
</script>

<style lang="scss" scoped>
.add {
    ::v-deep.el-dialog {
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    .addGauge {

        // .searchBtn {
        //     margin-top: 20px;
        //     // display: flex;

        //     .el-input,
        //     .el-select,
        //     .el-cascader {
        //         margin-right: 30px;
        //         width: 150px;
        //         // margin-bottom: 10px;
        //     }

        //     .el-button {
        //         margin-left: 20px;
        //     }
        // }

        .tableList {
            margin-top: 20px;
        }
    }

    .titleContent {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 16px;
        }
    }
}
</style>