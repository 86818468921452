<template>
  <div class="CrisisIntervention">
    <!-- 危机干预 -->
    <div class="searchBtn">
      <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
        <el-option label="全部" :value="4"></el-option>
        <el-option label="男" :value="1"></el-option>
        <el-option label="女" :value="2"></el-option>
        <el-option label="未填写" :value="3"></el-option>
      </el-select>
      <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构" clearable
        :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>
      <el-select v-model="searchInfo.roleId" placeholder="请选择角色" size="small" @focus="getRolesList" clearable>
        <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchInfo.consultantId" placeholder="请选择咨询师" clearable size="small">
        <el-option v-for="item in memberList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
      </el-select>
      <el-select clearable v-model="searchInfo.status" placeholder="请选择干预状态" size="small">
        <el-option label="未开始" :value="1"></el-option>
        <el-option label="进行中" :value="2"></el-option>
        <el-option label="已结束" :value="3"></el-option>
      </el-select>
      <div class="bottom">
        <el-input placeholder="开始年龄" v-model="searchInfo.startAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')" style="margin-right:10px"></el-input>
        <span>至</span>
        <el-input placeholder="结束年龄" v-model="searchInfo.endAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
        <el-date-picker size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
          value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
        </el-date-picker>
        <span>至</span>
        <el-date-picker size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
        <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" clearable size="small">
        </el-input>
        <el-button type="primary" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <div class="tableContent">
      <div class="top">
        <el-button type="primary" size="small" @click="add">新增</el-button>
        <el-button type="primary" size="small" @click="exportData">导出数据</el-button>
        <!-- <el-button type="primary" size="small">导出报告</el-button> -->
        <el-button type="primary" size="small" @click="deleteData">删除</el-button>
      </div>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column fixed prop="userId" label="用户ID" width="80">
          </el-table-column>
          <el-table-column prop="username" label="登录名" width="120" fixed>
          </el-table-column>
          <el-table-column prop="nickname" label="姓名" width="120" show-overflow-tooltip fixed>
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="120" fixed>
            <template slot-scope="scope">
              <span v-if="scope.row.sex === 1">男</span>
              <span v-else-if="scope.row.sex === 2">女</span>
              <span v-else-if="scope.row.sex === 3">未填写</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" width="120">
          </el-table-column>
          <el-table-column prop="deptName" label="组织机构" width="120">
          </el-table-column>
          <el-table-column prop="roleName" label="角色" width="120">
          </el-table-column>
          <el-table-column prop="consultantName" label="咨询师姓名" width="120">
          </el-table-column>
          <el-table-column prop="level" label="干预级别" width="120">
            <template slot-scope="scope">
              <el-select v-model="scope.row.level" @change="changeStatus(scope.row)" placeholder="请选择干预级别" size="small">
                <el-option label="一级" :value="1"></el-option>
                <el-option label="二级" :value="2"></el-option>
                <el-option label="三级" :value="3"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="干预状态" width="120">
            <template slot-scope="scope">
              <el-select v-model="scope.row.status" @change="changeStatus(scope.row)" placeholder="请选择状态" size="small">
                <el-option label="未开始" :value="1"></el-option>
                <el-option label="进行中" :value="2"></el-option>
                <el-option label="已结束" :value="3"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="createdName" label="创建人" width="120">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="160">
            <template slot-scope="scope">
              <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
              <el-button type="text" size="small" @click="update(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="lookArchives(scope.row.id)">干预报告</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
          layout="total, prev, pager, next, jumper" :total="paging.total">
        </el-pagination>
      </div>
    </div>
    <AddCrisisVue ref="addUser" @getList="getList"></AddCrisisVue>
    <UpdateCrisisVue ref="updateUser" @getList="getList"></UpdateCrisisVue>
  </div>
</template>

<script>
import AddCrisisVue from './AddCrisis.vue'
import UpdateCrisisVue from './UpdateCrisis.vue'
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { getInterveneList, updateArchives, deleteArchives, getTeachersList } from '@/api/dangerWarning/crisisIntervention'
import { getUsersList } from '@/api/system/users'
export default {
  components: { AddCrisisVue, UpdateCrisisVue },
  data() {
    return {
      // 顶部搜索的内容
      searchInfo: {
        deptId: null, // 组织机构id
        status: null, // 状态
        roleId: null, // 角色id
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        nickname: null, // 姓名
        startAge: null, // 开始年龄
        consultantId: null,  //咨询师
        sex: null, // 性别
        endAge: null // 结束年龄
      },
      memberList: [], // 人员列表
      // 角色数组
      RolesList: null,
      // 组织机构数组
      OrganizationsList: null,
      tableData: [],
      paging: {
        p: 1,
        ps: 16,
        total: 0
      },
      deleteList: [] // 需要删除的数组
    }
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      console.log('组织机构');
      console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        // this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
      }

    },
    // 获取角色列表
    async getRolesList() {
      const res = await getRoleList();
      console.log(res);
      if (res.code === 200) {
        this.RolesList = res.data;
        // this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
      }

    },
    // 获取咨询师列表
    async getUserList() {
      const res = await getTeachersList();
      console.log(res);
      if (res.code === 200) {
        this.memberList = res.data;
      }
    },
    // 获取干预列表
    async getList() {
      let info = { ...this.searchInfo, p: this.paging.p, ps: this.paging.ps }
      // 性别选择为4是是全部，将该字段的值改为null
      if (info.sex === 4) {
        info.sex = null;
      }
      const res = await getInterveneList(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.intervenePageVOList;
        this.paging.total = res.data.count;
      }
    },
    // 搜索
    search() {
      this.getList();
      console.log(this.searchInfo)
    },
    //用户点击选择
    handleSelectionChange(val) {
      console.log(val);
      this.deleteList = val;
    },
    // 修改干预状态
    async changeStatus(val) {
      // console.log(val)
      let info = { id: val.id, status: val.status, level: val.level }
      const res = await updateArchives(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功！',
          type: 'success'
        });
        this.getList();
      } else {
        this.$message({
          showClose: true,
          message: '修改失败！' + res.msg,
          type: 'error'
        });
      }
    },
    // 查看档案
    lookArchives(val) {
      this.$router.push({
        path: '/home/archives',
        query: {
          id: val
        }
      })
    },
    // 点击新增
    add() {
      // console.log(111)
      this.$refs.addUser.openAdd();
    },
    // 分页
    handleCurrentChange(val) {
      console.log(val)
      this.paging.p = val;
    },
    // 删除档案
    async deleteData() {

      let arr = [];
      if (this.deleteList.length > 0) {
        this.$confirm('确认删除该预警档案?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.deleteList.forEach(item => {
            arr.push(item.id);
          });
          const res = await deleteArchives(JSON.stringify(arr));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
            this.getList();
          } else {
            this.$message({
              showClose: true,
              message: '删除失败！' + res.msg,
              type: 'error'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      } else {
        this.$message({
          showClose: true,
          message: '请选择需要操作的干预档案！'
        });
      }
    },
    update(val) {
      this.$refs.updateUser.openAdd({ id: val.id, userId: val.userId, status: val.status, consultantId: val.consultantId });
    },
    exportData() {
      // let list = {...this.deleteList};
      if(this.deleteList.length > 0) {
        let arr = [];
      this.deleteList.forEach(item => {
        arr.push(item.id);
      })
      let list = arr.join(',');
      const elink = document.createElement('a');
      elink.href = `${this.$store.state.requestApi}/warning/export/intervene?ids=${list}`;
      // elink.href = `http://101.34.136.224:8080/warning/export/intervene?ids=${list}`;
      elink.click();
      } else {
        this.$message({
          showClose: true,
          message: '请先选择干预数据！'
        });
      }
    }

  },
  created() {
    this.getList();
    this.getUserList();
    this.getOrganizationsList();
    this.getRolesList();

  }
}
</script>

<style lang="scss" scoped>
.CrisisIntervention {
  padding: 20px;

  .bottom {
    margin-top: 20px;

    ::v-deep.el-date-editor {
      width: 150px !important;
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .tableContent {
    margin-top: 40px;

    .top {

      span,
      .el-button {
        display: inline-block;
        margin-right: 10px;
        margin-left: 0px;
      }
    }

    .table {
      margin-top: 10px;
    }
  }

  .block {
    margin-top: 20px;
  }
}
</style>